// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------
// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// locales
import {LocalizationProvider} from 'src/locales';
// hooks
import {useScrollToTop} from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import {MotionLazy} from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import {SettingsDrawer, SettingsProvider} from 'src/components/settings';
// sections
// import { CheckoutProvider } from 'src/sections/checkout/context';
// auth
import {AuthConsumer, AuthProvider} from 'src/auth/context/jwt';
import {QueryClient, QueryClientProvider} from 'react-query';
import {Alert, Box, Button, Snackbar} from '@mui/material';
import {useEffect, useState} from 'react';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/auth0';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/amplify';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/firebase';
import {BUILT_AT} from 'src/config-global';
import dayjs from 'dayjs';
import Iconify from './components/iconify';
// ----------------------------------------------------------------------

type Toast = {
  open: boolean;
  vertical: string | any;
  horizontal: string | any;
  message: string;
};

export default function App() {
  useScrollToTop();
  const isOpen = sessionStorage.getItem('tokenExpired');
  const [toast, setToast] = useState<Toast>({
    open: !!isOpen && isOpen === 'true',
    vertical: 'top',
    horizontal: 'center',
    message: '',
  });

  const [toastVersion, setToastVersion] = useState<Toast>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
  });

  const { vertical, horizontal, open } = toast;
  const {
    vertical: verticalVersion,
    horizontal: horizontalVersion,
    open: openVersion,
  } = toastVersion;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const checkVersion = async () => {
    try {
      const version = await fetch('/build-info.json');
      const versionData = await version.json();
      if (versionData && dayjs(BUILT_AT).isBefore(dayjs(versionData.built_at))) {
        setToastVersion((prev) => ({ ...prev, open: true }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(checkVersion, 1 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [checkVersion]);

  useEffect(() => {
    checkVersion().then(r => {});
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <LocalizationProvider>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'mini', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}
          >
            <ThemeProvider>
              <SnackbarProvider>
                <MotionLazy>
                  {/* <CheckoutProvider> */}
                  <SettingsDrawer />
                  <ProgressBar />
                  <AuthConsumer>
                    <Router />
                  </AuthConsumer>
                  {/* </CheckoutProvider> */}
                  <Snackbar
                    sx={{ zIndex: 9999 }}
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={() => {
                      setToast({ ...toast, open: false });
                      sessionStorage.setItem('tokenExpired', 'false');
                    }}
                    autoHideDuration={5000}
                    key={vertical + horizontal}
                  >
                    <Alert
                      onClose={() => {
                        setToast({ ...toast, open: false });
                        sessionStorage.setItem('tokenExpired', 'false');
                      }}
                      severity="error"
                      sx={{ width: '100%' }}
                    >
                      The login session has expired. Please log in again.
                    </Alert>
                  </Snackbar>
                  <Snackbar
                    sx={{ zIndex: 9999 }}
                    anchorOrigin={{ vertical: verticalVersion, horizontal: horizontalVersion }}
                    open={openVersion}
                    onClose={() => {
                      setToastVersion({ ...toastVersion, open: false });
                    }}
                    key={vertical + horizontal}
                  >
                    <Alert
                      icon={
                        <Box>
                          <Iconify icon="mdi:new-box" />
                        </Box>
                      }
                      onClose={() => {
                        setToastVersion({ ...toastVersion, open: false });
                      }}
                      severity="info"
                      sx={{ width: '100%' }}
                    >
                      A new version is available
                      <Button
                        sx={{ ml: 2 }}
                        variant="outlined"
                        onClick={() => window.location.reload()}
                      >
                        Reload
                      </Button>
                    </Alert>
                  </Snackbar>
                </MotionLazy>
              </SnackbarProvider>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
