import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {AuthGuard} from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import {LoadingScreen, SplashScreen} from 'src/components/loading-screen';
import CompactLayout from '../../layouts/compact';
import ErrorBoundary from "../../components/error-boundary";
// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
// const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
// const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
// const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
// const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
// const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));

// ADMIN
const GatewaysListPage = lazy(() => import('src/pages/dashboard/admin/gateways/list'));
const GatewaysEditPage = lazy(() => import('src/pages/dashboard/admin/gateways/edit'));
const GatewaysCreatePage = lazy(() => import('src/pages/dashboard/admin/gateways/new'));
// FULFILLS
const FulfillsListPage = lazy(() => import('src/pages/dashboard/admin/fulfills/list'));
const FulfillsEditPage = lazy(() => import('src/pages/dashboard/admin/fulfills/edit'));
const FulfillsCreatePage = lazy(() => import('src/pages/dashboard/admin/fulfills/new'));
// PRODUCTS
const ProductListPage = lazy(() => import('src/pages/dashboard/admin/fulfill-product/list'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/admin/fulfill-product/edit'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/admin/fulfill-product/new'));
// SELLER FEE
const SellerFeesListPage = lazy(() => import('src/pages/dashboard/admin/seller-fees/list'));
const SellerFeesEditPage = lazy(() => import('src/pages/dashboard/admin/seller-fees/edit'));
const SellerFeesCreatePage = lazy(() => import('src/pages/dashboard/admin/seller-fees/new'));
// SELLER PRODUCTS
const SellerProductsListPage = lazy(() => import('src/pages/dashboard/admin/seller-products/list'));
const SellerProductsEditPage = lazy(() => import('src/pages/dashboard/admin/seller-products/edit'));
const SellerProductsCreatePage = lazy(
  () => import('src/pages/dashboard/admin/seller-products/new')
);
// STORE
const StoresListPage = lazy(() => import('src/pages/dashboard/admin/stores/list'));
const StoresEditPage = lazy(() => import('src/pages/dashboard/admin/stores/edit'));
const StoresCreatePage = lazy(() => import('src/pages/dashboard/admin/stores/new'));
// STORE
const StoreProductsListPage = lazy(() => import('src/pages/dashboard/admin/store-products/list'));
const StoreProductsEditPage = lazy(() => import('src/pages/dashboard/admin/store-products/edit'));
// USERS
const UsersListPage = lazy(() => import('src/pages/dashboard/admin/users/list'));
const UsersEditPage = lazy(() => import('src/pages/dashboard/admin/users/edit'));
const UsersCreatePage = lazy(() => import('src/pages/dashboard/admin/users/new'));
// PAYOUTS
const PayoutsListPage = lazy(() => import('src/pages/dashboard/admin/payouts/list'));
const PayoutsEditPage = lazy(() => import('src/pages/dashboard/admin/payouts/edit'));
const PayoutsCreatePage = lazy(() => import('src/pages/dashboard/admin/payouts/new'));
// PIXEL
const PixelsListPage = lazy(() => import('src/pages/dashboard/admin/pixels/list'));
// BACKGROUND TASKS
const BackgroundTasksListPage = lazy(
  () => import('src/pages/dashboard/admin/background-tasks/list')
);
// USER ACTIVITIES
const UserActivitiesListPage = lazy(
  () => import('src/pages/dashboard/admin/user_activities/list')
);
// ORDERS
const OrdersListPage = lazy(() => import('src/pages/dashboard/shop/orders/list'));
const OrdersDetailPage = lazy(() => import('src/pages/dashboard/shop/orders/detail'));
// DISPUTES
const DisputesListPage = lazy(() => import('src/pages/dashboard/paypal/disputes/list'));
const DisputesDetailPage = lazy(() => import('src/pages/dashboard/paypal/disputes/detail'));
// PAYOUTS
const PayoutListPage = lazy(() => import('src/pages/dashboard/paypal/payouts/list'));
// REVENUE
const RefundListPage = lazy(() => import('src/pages/dashboard/analytics/refund/list'));
// REFUND
const RevenueListPage = lazy(() => import('src/pages/dashboard/analytics/revenue/list'));
// TRAFFIC
const TrafficListPage = lazy(() => import('src/pages/dashboard/analytics/traffic/list'));
// ONLINE USERS
const OnlineUsersListPage = lazy(() => import('src/pages/dashboard/analytics/online-users/list'));
// WALLET
const WalletListPage = lazy(() => import('src/pages/dashboard/finance/wallet/list'));
const RequestWithdrawPage = lazy(
  () => import('src/pages/dashboard/finance/wallet/request-withdraw')
);
const ReleaseScheduleListPage = lazy(
  () => import('src/pages/dashboard/finance/release-schedule/list')
);
// AD LIBRARY TASK
const AdLibraryTasksListPage = lazy(() => import('src/pages/dashboard/ad-libary/tasks/list'));
const AdLibraryTasksCreatePage = lazy(() => import('src/pages/dashboard/ad-libary/tasks/new'));
// AD LIBRARY AD
const AdLibraryAdsListPage = lazy(() => import('src/pages/dashboard/ad-libary/ads/list'));
// SHARE PIXEL
const SharePixelListPage = lazy(() => import('src/pages/dashboard/tool/share-pixels/list'));
const SharesPage = lazy(() => import('src/pages/dashboard/tool/share-pixels/shares'));
// BIN CHECKER
const BinCheckerListPage = lazy(() => import('src/pages/dashboard/tool/bin-checker/list'));
// NOTIFICATIONS
const NotificationsListPage = lazy(() => import('src/pages/dashboard/tool/notifications/list'));
// DSP PRODUCTS
const DspProductListPage = lazy(() => import('src/pages/dashboard/dsp/products/list'));
const DspProductView = lazy(() => import('src/pages/dashboard/dsp/products/view'));
// DSP LINKS
const DspLinkListPage = lazy(() => import('src/pages/dashboard/dsp/links/list'));
// QUOTATION
const QuotationListPage = lazy(() => import('src/pages/dashboard/dsp/quotation/list'));
const QuotationEditPage = lazy(() => import('src/pages/dashboard/dsp/quotation/edit'));
const QuotationCreatePage = lazy(() => import('src/pages/dashboard/dsp/quotation/new'));
// // PRODUCT
// const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
// const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
// const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
// const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// // ORDER
// const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
// const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// // INVOICE
// const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
// const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
// const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
// const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// // USER
// const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
// const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
// const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/admin/user/account'));
// const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
// const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// // BLOG
// const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
// const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
// const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
// const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// // JOB
// const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
// const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
// const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
// const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// // TOUR
// const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
// const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
// const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
// const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// // FILE MANAGER
// const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// // APP
// const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
// const MailPage = lazy(() => import('src/pages/dashboard/mail'));
// const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
// const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// // TEST RENDER PAGE BY ROLE
// const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// // BLANK PAGE
// const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const Page404 = lazy(() => import('src/pages/404'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <ErrorBoundary>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      // { path: 'ecommerce', element: <OverviewEcommercePage /> },
      // { path: 'analytics', element: <OverviewAnalyticsPage /> },
      // { path: 'banking', element: <OverviewBankingPage /> },
      // { path: 'booking', element: <OverviewBookingPage /> },
      // { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          { element: <UserAccountPage />, index: true },
          // { path: 'profile', element: <UserProfilePage /> },
          // { path: 'cards', element: <UserCardsPage /> },
          // { path: 'list', element: <UserListPage /> },
          // { path: 'new', element: <UserCreatePage /> },
          // { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
          { path: 'account/general', element: <UserAccountPage /> },
          { path: 'account/password', element: <UserAccountPage /> },
          { path: 'account/notifications', element: <NotificationsListPage /> },
        ],
      },
      // {
      //   path: 'product',
      //   children: [
      //     { element: <ProductListPage />, index: true },
      //     { path: 'list', element: <ProductListPage /> },
      //     { path: ':id', element: <ProductDetailsPage /> },
      //     { path: 'new', element: <ProductCreatePage /> },
      //     { path: ':id/edit', element: <ProductEditPage /> },
      //   ],
      // },
      // {
      //   path: 'order',
      //   children: [
      //     { element: <OrderListPage />, index: true },
      //     { path: 'list', element: <OrderListPage /> },
      //     { path: ':id', element: <OrderDetailsPage /> },
      //   ],
      // },
      // {
      //   path: 'invoice',
      //   children: [
      //     { element: <InvoiceListPage />, index: true },
      //     { path: 'list', element: <InvoiceListPage /> },
      //     { path: ':id', element: <InvoiceDetailsPage /> },
      //     { path: ':id/edit', element: <InvoiceEditPage /> },
      //     { path: 'new', element: <InvoiceCreatePage /> },
      //   ],
      // },
      // {
      //   path: 'post',
      //   children: [
      //     { element: <BlogPostsPage />, index: true },
      //     { path: 'list', element: <BlogPostsPage /> },
      //     { path: ':title', element: <BlogPostPage /> },
      //     { path: ':title/edit', element: <BlogEditPostPage /> },
      //     { path: 'new', element: <BlogNewPostPage /> },
      //   ],
      // },
      // {
      //   path: 'job',
      //   children: [
      //     { element: <JobListPage />, index: true },
      //     { path: 'list', element: <JobListPage /> },
      //     { path: ':id', element: <JobDetailsPage /> },
      //     { path: 'new', element: <JobCreatePage /> },
      //     { path: ':id/edit', element: <JobEditPage /> },
      //   ],
      // },
      // {
      //   path: 'tour',
      //   children: [
      //     { element: <TourListPage />, index: true },
      //     { path: 'list', element: <TourListPage /> },
      //     { path: ':id', element: <TourDetailsPage /> },
      //     { path: 'new', element: <TourCreatePage /> },
      //     { path: ':id/edit', element: <TourEditPage /> },
      //   ],
      // },
      // { path: 'file-manager', element: <FileManagerPage /> },
      // { path: 'mail', element: <MailPage /> },
      // { path: 'chat', element: <ChatPage /> },
      // { path: 'calendar', element: <CalendarPage /> },
      // { path: 'kanban', element: <KanbanPage /> },
      // { path: 'permission', element: <PermissionDeniedPage /> },
      // { path: 'blank', element: <BlankPage /> },
      {
        path: 'admin/gateways',
        children: [
          { element: <GatewaysListPage />, index: true },
          { path: 'new', element: <GatewaysCreatePage /> },
          { path: ':id/edit', element: <GatewaysEditPage /> },
        ],
      },
      {
        path: 'admin/fulfills',
        children: [
          { element: <FulfillsListPage />, index: true },
          { path: 'new', element: <FulfillsCreatePage /> },
          { path: ':id/edit', element: <FulfillsEditPage /> },
        ],
      },
      {
        path: 'admin/products',
        children: [
          { element: <ProductListPage />, index: true },
          // { path: 'list', element: <ProductListPage /> },
          // { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'admin/seller-fees',
        children: [
          { element: <SellerFeesListPage />, index: true },
          // { path: 'list', element: <ProductListPage /> },
          // { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <SellerFeesCreatePage /> },
          { path: ':id/edit', element: <SellerFeesEditPage /> },
        ],
      },
      {
        path: 'admin/seller-products',
        children: [
          { element: <SellerProductsListPage />, index: true },
          { path: 'new', element: <SellerProductsCreatePage /> },
          { path: ':id/edit', element: <SellerProductsEditPage /> },
        ],
      },
      {
        path: 'admin/stores',
        children: [
          { element: <StoresListPage />, index: true },
          { path: 'new', element: <StoresCreatePage /> },
          { path: ':id/edit', element: <StoresEditPage /> },
        ],
      },
      {
        path: 'admin/store-products',
        children: [
          { element: <StoreProductsListPage />, index: true },
          { path: ':id/edit', element: <StoreProductsEditPage /> },
        ],
      },
      {
        path: 'admin/users',
        children: [
          { element: <UsersListPage />, index: true },
          { path: 'new', element: <UsersCreatePage /> },
          { path: ':id/edit', element: <UsersEditPage /> },
        ],
      },
      {
        path: 'admin/payouts',
        children: [
          { element: <PayoutsListPage />, index: true },
          { path: 'new', element: <PayoutsCreatePage /> },
          { path: ':id/edit', element: <PayoutsEditPage /> },
        ],
      },
      {
        path: 'admin/pixels',
        children: [{ element: <PixelsListPage />, index: true }],
      },
      {
        path: 'admin/background-tasks',
        children: [{ element: <BackgroundTasksListPage />, index: true }],
      },
      {
        path: 'admin/user-activities',
        children: [{ element: <UserActivitiesListPage />, index: true }],
      },
      {
        path: 'paypal/disputes',
        children: [
          { element: <DisputesListPage />, index: true },
          { path: ':id/view', element: <DisputesDetailPage /> },
        ],
      },
      {
        path: 'paypal/payouts',
        children: [
          { element: <PayoutListPage />, index: true },
        ],
      },
      {
        path: 'shop/orders',
        children: [
          { element: <OrdersListPage />, index: true },
          { path: ':id/view', element: <OrdersDetailPage /> }
        ],
      },
      {
        path: 'finance/wallet',
        children: [{ element: <WalletListPage />, index: true }],
      },
      {
        path: 'finance/wallet/request-withdraw',
        children: [{ element: <RequestWithdrawPage />, index: true }],
      },
      {
        path: 'finance/wallet/release-schedule',
        children: [{ element: <ReleaseScheduleListPage />, index: true }],
      },
      {
        path: 'analytics/revenue',
        children: [{ element: <RevenueListPage />, index: true }],
      },
      {
        path: 'analytics/refund',
        children: [{ element: <RefundListPage />, index: true }],
      },
      {
        path: 'analytics/online-users',
        children: [{ element: <OnlineUsersListPage />, index: true }],
      },
      {
        path: 'analytics/traffic',
        children: [{ element: <TrafficListPage />, index: true }],
      },
      {
        path: 'ad-library/tasks',
        children: [
          { element: <AdLibraryTasksListPage />, index: true },
          { path: 'new', element: <AdLibraryTasksCreatePage /> },
        ],
      },
      {
        path: 'ad-library/ads',
        children: [{ element: <AdLibraryAdsListPage />, index: true }],
      },
      {
        path: 'tool/share-pixel',
        children: [
          { element: <SharePixelListPage />, index: true },
          { path: 'shares', element: <SharesPage /> },
        ],
      },
      {
        path: 'tool/bin-checker',
        children: [{ element: <BinCheckerListPage />, index: true }],
      },
      {
        path: 'tool/quotation',
        children: [
          { element: <QuotationListPage />, index: true },
          { path: 'new', element: <QuotationCreatePage /> },
          { path: ':id/edit', element: <QuotationEditPage /> },
        ],
      },
      {
        path: 'dsp/products',
        children: [
          { element: <DspProductListPage />, index: true },
          { path: ':id/view', element: <DspProductView /> }
        ],
      },
      {
        path: 'dsp/links',
        children: [
          { element: <DspLinkListPage />, index: true },
        ],
      },
      {
        path: 'dsp/quotations',
        children: [
          { element: <QuotationListPage />, index: true },
          { path: 'new', element: <QuotationCreatePage /> },
          { path: ':id/edit', element: <QuotationEditPage /> },
        ],
      },
    ],
  },
  {
    path: '404',
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Page404 />
        </Suspense>
      </CompactLayout>
    ),
  },
];
