// utils
import {paramCase} from 'src/utils/change-case';
import {_id, _postTitles} from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth',
  DASHBOARD: '',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/login`,
      register: `${ROOTS.AUTH_DEMO}/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    gateways: {
      root: `${ROOTS.DASHBOARD}/admin/gateways`,
      new: `${ROOTS.DASHBOARD}/admin/gateways/new`,
      details: (id: number) => `${ROOTS.DASHBOARD}/admin/gateways/${id}`,
      edit: (id: number) => `${ROOTS.DASHBOARD}/admin/gateways/${id}/edit`,
    },
    fulfills: {
      root: `${ROOTS.DASHBOARD}/admin/fulfills`,
      new: `${ROOTS.DASHBOARD}/admin/fulfills/new`,
      details: (id: number) => `${ROOTS.DASHBOARD}/admin/fulfills/${id}`,
      edit: (id: number) => `${ROOTS.DASHBOARD}/admin/fulfills/${id}/edit`,
    },
    products: {
      root: `${ROOTS.DASHBOARD}/admin/products`,
      new: `${ROOTS.DASHBOARD}/admin/products/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/admin/products/${id}`,
      edit: (id: string | number) => `${ROOTS.DASHBOARD}/admin/products/${id}/edit`,
    },
    sellerFees: {
      root: `${ROOTS.DASHBOARD}/admin/seller-fees`,
      new: `${ROOTS.DASHBOARD}/admin/seller-fees/new`,
      details: (id: number) => `${ROOTS.DASHBOARD}/admin/seller-fees/${id}`,
      edit: (id: number | string) => `${ROOTS.DASHBOARD}/admin/seller-fees/${id}/edit`,
    },
    sellerProducts: {
      root: `${ROOTS.DASHBOARD}/admin/seller-products`,
      new: `${ROOTS.DASHBOARD}/admin/seller-products/new`,
      details: (id: number) => `${ROOTS.DASHBOARD}/admin/seller-products/${id}`,
      edit: (id: number) => `${ROOTS.DASHBOARD}/admin/seller-products/${id}/edit`,
    },
    stores: {
      root: `${ROOTS.DASHBOARD}/admin/stores`,
      new: `${ROOTS.DASHBOARD}/admin/stores/new`,
      details: (id: number) => `${ROOTS.DASHBOARD}/admin/stores/${id}`,
      edit: (id: number) => `${ROOTS.DASHBOARD}/admin/stores/${id}/edit`,
    },
    storeProducts: {
      root: `${ROOTS.DASHBOARD}/admin/store-products`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/admin/store-products/${id}/edit`,
    },
    users: {
      root: `${ROOTS.DASHBOARD}/admin/users`,
      new: `${ROOTS.DASHBOARD}/admin/users/new`,
      edit: (id: string | number) => `${ROOTS.DASHBOARD}/admin/users/${id}/edit`,
    },
    payouts: {
      root: `${ROOTS.DASHBOARD}/admin/payouts`,
      new: `${ROOTS.DASHBOARD}/admin/payouts/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/admin/payouts/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/admin/payouts/${id}/edit`,
    },
    pixels: {
      root: `${ROOTS.DASHBOARD}/admin/pixels`,
    },
    backgroundTasks: {
      root: `${ROOTS.DASHBOARD}/admin/background-tasks`,
    },
    userActivities: {
      root: `${ROOTS.DASHBOARD}/admin/user-activities`,
    },
    disputes: {
      root: `${ROOTS.DASHBOARD}/paypal/disputes`,
      view: (id: string) => `${ROOTS.DASHBOARD}/paypal/disputes/${id}/view`,
      transactionsView: (transactions_id: string) =>
        `${ROOTS.DASHBOARD}/paypal/transactions/${transactions_id}/view`,
      disputesView: (dispute_id: string) => `${ROOTS.DASHBOARD}/paypal/disputes/${dispute_id}/view`,
    },
    paypalPayouts: {
      root: `${ROOTS.DASHBOARD}/paypal/payouts`,
    },
    orders: {
      root: `${ROOTS.DASHBOARD}/shop/orders`,
      detail: (id: string) => `${ROOTS.DASHBOARD}/shop/orders/${id}/view`,
    },
    revenue: {
      root: `${ROOTS.DASHBOARD}/analytics/revenue`,
    },
    refund: {
      root: `${ROOTS.DASHBOARD}/analytics/refund`,
    },
    traffic: {
      root: `${ROOTS.DASHBOARD}/analytics/traffic`,
    },
    onlineUsers: {
      root: `${ROOTS.DASHBOARD}/analytics/online-users`,
    },
    wallet: {
      root: `${ROOTS.DASHBOARD}/finance/wallet`,
      requestWithdraw: `${ROOTS.DASHBOARD}/finance/wallet/request-withdraw`,
      releaseSchedule: `${ROOTS.DASHBOARD}/finance/wallet/release-schedule`,
    },
    releaseSchedule: {
      root: `${ROOTS.DASHBOARD}/legacy/release-schedule`,
    },
    adLibraryTasks: {
      root: `${ROOTS.DASHBOARD}/ad-library/tasks`,
      new: `${ROOTS.DASHBOARD}/ad-library/tasks/new`,
      details: (id: number) => `${ROOTS.DASHBOARD}/ad-library/tasks/${id}`,
      edit: (id: number) => `${ROOTS.DASHBOARD}/ad-library/tasks/${id}/edit`,
    },
    adLibraryAds: {
      root: `${ROOTS.DASHBOARD}/ad-library/ads`,
    },
    sharePixel: {
      root: `${ROOTS.DASHBOARD}/tool/share-pixel`,
      share: `${ROOTS.DASHBOARD}/tool/share-pixel/shares`,
    },
    binChecker: {
      root: `${ROOTS.DASHBOARD}/tool/bin-checker`,
    },
    dspProducts: {
      root: `${ROOTS.DASHBOARD}/dsp/products`,
      view: (id: string | number) => `${ROOTS.DASHBOARD}/dsp/products/${id}/view`,
      edit: (id: string | number) => `${ROOTS.DASHBOARD}/admin/seller-products/${id}/edit`,
    },
    dspLinks: {
      root: `${ROOTS.DASHBOARD}/dsp/links`,
    },
    quotation: {
      root: `${ROOTS.DASHBOARD}/dsp/quotations`,
      new: `${ROOTS.DASHBOARD}/dsp/quotations/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/dsp/quotations/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/dsp/quotations/${id}/edit`,
    },
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: {
        origin: `${ROOTS.DASHBOARD}/user/account`,
        notifiations: `${ROOTS.DASHBOARD}/user/account/notifications`,
      },
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
};
