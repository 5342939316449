import React from 'react';
import {ErrorBoundaryProps, ErrorBoundaryState} from './types';
import {MotionContainer, varBounce} from '../animate';
import {m} from 'framer-motion';
import {Button, Typography} from '@mui/material';
import {ForbiddenIllustration} from 'src/assets/illustrations';

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static isFailedImportModuleError(error: Error | null): boolean {
    if (!error || error.name !== 'TypeError') {
      return false;
    }

    const messages = [
      'Failed to fetch dynamically imported module',
      'Importing a module script failed.',
      'Load failed',
    ];
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      if (error.message.startsWith(message)) {
        return true;
      }
    }
    return false;
  }

  static isIgnoreError(error: Error | null): boolean {
    if (!error) {
      return true;
    }
    if (ErrorBoundary.isFailedImportModuleError(error)) {
      return true;
    }

    console.log(error);

    const {message} = error;
    if (error.name === 'TypeError') {
      // temporary ignore, https://github.com/t49tran/react-google-recaptcha-v3/issues/182
      if (
        message.startsWith('Cannot read properties of undefined (reading')
        && error.stack?.includes('react-google-recaptcha-v3')
      ) {
        return true;
      }
      // TypeError undefined is not an object (evaluating 'window.___grecaptcha_cfg[H[2]][h]')
      if (message.includes('window.___grecaptcha_cfg')) {
        return true;
      }
    }

    return false;
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, componentStack: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ error, componentStack: info.componentStack });
  }

  render() {
    const { error, componentStack } = this.state;
    if (!error) {
      const { children } = this.props;
      return children;
    }

    if (ErrorBoundary.isFailedImportModuleError(error)) {
      const { location } = window;
      const searchParams = new URLSearchParams(location.search);

      if (searchParams.get('refresh') !== 'true') {
        searchParams.set('refresh', 'true');
        window.location.href = `${location.pathname}?${searchParams.toString()}`;
        return (<></>);
      }

      return (
        <MotionContainer
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <m.div variants={varBounce().in}>
            <Typography variant="h3" sx={{ mb: 2 }}>
              Failed to load screen
            </Typography>
          </m.div>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" sx={{ mb: 2 }}>
              Maybe have a new version is available
            </Typography>
          </m.div>

          <Button
            onClick={() => {
              window.location.reload();
            }}
            size="large"
            variant="contained"
          >
            Try to Reload
          </Button>
          <details style={{ whiteSpace: 'pre-wrap' }}>{error && error.toString()}</details>
        </MotionContainer>
      );
    }

    console.error(error);

    return (
      <MotionContainer
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Something went wrong
          </Typography>
        </m.div>
        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, an error occurred. Please try again later or contact support.
          </Typography>
        </m.div>
        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>

        <details style={{ whiteSpace: 'pre-wrap' }}>
          {error && error.toString()}
          <br />
          {componentStack}
        </details>
      </MotionContainer>
    );
  }
}
