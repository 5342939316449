import { Navigate, useRoutes } from 'react-router-dom';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

export default function Router() {
  return useRoutes([
    ...authRoutes,
    ...dashboardRoutes,

    // Main routes
    // ...mainRoutes,

    // Components routes
    // ...componentsRoutes,

    // No match 404
    { path: '*', element: <HandleRedirect404 /> },
  ]);
}

const HandleRedirect404 = () => {
  sessionStorage.setItem('holding-router-path', window.location.href);
  return <Navigate to="/404" replace />;
};
