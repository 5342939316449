import {Role} from "../../auth/guard/role";

export const hasRole = (requiredRoles: string[] | undefined, currentRoles: string[] | undefined) => {
    if (currentRoles && currentRoles.includes(Role.ADMIN)) {
        return true;
    }

    if (!requiredRoles) {
        return true;
    }

    for (let i = 0; i < requiredRoles.length; i++) {
        if (currentRoles?.includes(requiredRoles[i])) {
            return true;
        }
    }

    return false;
};
