import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.params && config.params.filters) {
      Object.keys(config.params.filters).forEach((key) => {
        if (config.params.filters[key] === '') {
          delete config.params.filters[key];
        }
      });
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || { message: error.message })
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const method = config?.method || 'GET';

  const res = await axiosInstance.request({ ...config, url, method });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  account: {
    notifications: '/api/v1.0/notifications/setting',
  },
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/v1.0/auth/me',
    login: '/api/v1.0/auth/login',
    signUp: '/api/v1.0/auth/sign-up',
    register: '/api/v1.0/auth/register',
    change_password: '/api/v1.0/auth/change-password',
    forgot_password: '/api/v1.0/auth/forgot-password',
    reset_password: '/api/v1.0/auth/reset-password',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  gateways: {
    base: '/api/v1.0/gateways',
    list: '/api/v1.0/gateways',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  fulfills: {
    base: '/api/v1.0/fulfills',
    list: '/api/v1.0/fulfills',
  },
  products: {
    base: '/api/v1.0/products',
    list: '/api/v1.0/products',
  },
  sellerFees: {
    base: '/api/v1.0/seller-fees',
    list: '/api/v1.0/seller-fees',
  },
  sellerProducts: {
    base: '/api/v1.0/seller-products',
    list: '/api/v1.0/seller-products',
  },
  stores: {
    base: '/api/v1.0/stores',
    list: '/api/v1.0/stores',
    re_calculate_report: '/api/v1.0/stores/re-calculate-report',
    re_calculate_orders: '/api/v1.0/stores/re-calculate-orders',
  },
  version: {
    base: '/build-info.json'
  },
  storeProducts: {
    base: '/api/v1.0/store-products',
    list: '/api/v1.0/store-products',
  },
  users: {
    base: '/api/v1.0/users',
    list: '/api/v1.0/users',
    loginAs: '/api/v1.0/auth/login-as',
  },
  payouts: {
    base: '/api/v1.0/payouts',
    list: '/api/v1.0/payouts',
  },
  pixel: {
    base: '/api/v1.0/pixels',
    list: '/api/v1.0/pixels',
  },
  backgroundTasks: {
    base: '/api/v1.0/background-tasks',
    list: '/api/v1.0/background-tasks',
    stop: '/api/v1.0/background-tasks/stop',
    workers: '/api/v1.0/select-options/background-tasks/workers',
  },
  userAtivities: {
    base: '/api/v1.0/user-activities',
    list: '/api/v1.0/user-activities',
    actions: '/api/v1.0/select-options/user-activities/actions',
    resource_name: '/api/v1.0/select-options/user-activities/resource-names',
  },
  disputes: {
    base: '/api/v1.0/paypal/disputes',
    list: '/api/v1.0/paypal/disputes',
    statusOptions: '/api/v1.0/select-options/paypal/dispute/status',
  },
  paypalPayouts: {
    base: '/api/v1.0/paypal/payouts',
    list: '/api/v1.0/paypal/payouts',
  },
  revenue: {
    base: '/api/v1.0/analytics/revenue',
    list: '/api/v1.0/analytics/revenue',
    analytic: '/api/v1.0/analytics/revenue',
  },
  refund: {
    base: '/api/v1.0/analytics/refund',
    list: '/api/v1.0/analytics/refund',
    analytic: '/api/v1.0/analytics/refund',
  },
  traffic: {
    base: '/api/v1.0/analytics/traffic',
    list: '/api/v1.0/analytics/traffic',
    analytic: '/api/v1.0/analytics/traffic',
  },
  onlineUsers: {
    base: '/api/v1.0/analytics/traffic/online-users',
  },
  orders: {
    base: '/api/v1.0/orders',
    list: '/api/v1.0/orders',
  },
  wallet: {
    base: '/api/v1.0/wallet',
    list: '/api/v1.0/wallet',
    banks: '/api/v1.0/banks',
    payouts: '/api/v1.0/payouts',
  },
  releaseSchedule: {
    base: '/api/v1.0/wallet/release-schedule',
    list: '/api/v1.0/wallet/release-schedule',
  },
  adLibraryTask: {
    base: '/api/v1.0/ad-library/tasks',
    list: '/api/v1.0/ad-library/tasks',
  },
  adLibraryAd: {
    base: '/api/v1.0/ad-library/ads',
    list: '/api/v1.0/ad-library/ads',
  },
  sharePixel: {
    base: '/api/v1.0/pixel-shared-accounts',
    list: '/api/v1.0/pixel-shared-accounts',
  },
  binChecker: {
    base: '/api/v1.0/bins/check',
    list: '/api/v1.0/bins/check',
    brand: '/api/v1.0/select-options/bins/brands',
    type: '/api/v1.0/select-options/bins/types',
    level: '/api/v1.0/select-options/bins/levels',
  },
  quotation: {
    base: '/api/v1.0/quotations',
    list: '/api/v1.0/quotations',
  },
};
