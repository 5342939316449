import {memo} from 'react';
// @mui
import Stack from '@mui/material/Stack';
// theme
import {hideScroll} from 'src/theme/css';
//
import {NavConfigProps, NavListProps, NavSectionProps} from '../types';
import {navHorizontalConfig} from '../config';
import NavList from './nav-list';
import {hasRole} from "../util";

// ----------------------------------------------------------------------

function NavSectionHorizontal({ data, config, sx, ...other }: NavSectionProps) {
  return (
    <Stack
      direction="row"
      sx={{
        mx: 'auto',
        ...hideScroll.y,
        ...sx,
      }}
      {...other}
    >
      {data.map((group, index) => {
          const {roles} = group;
          if (!hasRole(roles, config?.currentRoles)) {
              return null;
          }

          return (
              <Group
                  key={group.subheader || index}
                  items={group.items}
                  config={navHorizontalConfig(config)}
              />
          )
      })}
    </Stack>
  );
}

export default memo(NavSectionHorizontal);

// ----------------------------------------------------------------------

type GroupProps = {
  items: NavListProps[];
  config: NavConfigProps;
};

function Group({ items, config }: GroupProps) {
  return (
    <>
      {items.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
    </>
  );
}
