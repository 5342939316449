import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, createRoutesFromChildren, useLocation, useNavigationType} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import {matchRoutes} from "react-router";
import App from './App';
import ErrorBoundary from "./components/error-boundary";

export const {VITE_SENTRY_DSN, VITE_NODE_ENV, VITE_APP_VERSION} = import.meta.env;

if (VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    environment: VITE_NODE_ENV,
    release: VITE_APP_VERSION,
    beforeSend: (event, hint) => {
      const error = hint.originalException as Error;
      if (ErrorBoundary.isIgnoreError(error)) {
        return null;
      }

      return event;
    },
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <ErrorBoundary>
        <Suspense>
        {/* <SnackbarProvider> */}
          <App />
          {/* </SnackbarProvider> */}
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  </HelmetProvider>
);
